import { Button, DatePicker, Input, List, Select, Table, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';

const { Option } = Select;

const fetchParcelStatuses = async (): Promise<Record<string, string>> => {
  try {
    const { data } = await axios.get('api/internal/fix/parcel-statuses');
    return Object.fromEntries(data) as Record<string, string>;
  } catch (error) {
    console.error('Помилка під час отримання статусів:', error);
    throw new Error('Не вдалося отримати статуси');
  }
};

const parcelApiCall = async (id: string): Promise<any> => {
  try {
    const { data } = await axios.get(`/api/internal/fix/get-parcel-info`, {
      params: { id }
    });

    const formatDate = (date: string | null) =>
      date ? dayjs(date).format('DD/MM/YYYY HH:mm') : null;

    if (data.parsel) {
      data.parsel.deliveredDate = formatDate(data.parsel.deliveredDate);
      data.parsel.expirationDate = formatDate(data.parsel.expirationDate);
      data.parsel.createdAt = formatDate(data.parsel.createdAt);
      data.parsel.updatedAt = formatDate(data.parsel.updatedAt);
    }

    return data;
  } catch (error) {
    console.error('Помилка під час отримання даних посилки:', error);
    throw new Error('Не вдалося отримати дані посилки');
  }
};

const saveStatusesApiCall = async (statuses: any[]) => {
  try {
    const { data } = await axios.post('/api/internal/fix/update-parcel-statuses', statuses);
    return data;
  } catch (error) {
    console.error('Помилка під час збереження статусів:', error);
    throw new Error('Не вдалося зберегти статуси');
  }
};

export const ParcelListForDev: FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [parcelData, setParcelData] = useState<any | null>(null);
  const [editedStatuses, setEditedStatuses] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [statuses, setStatuses] = useState<Record<string, string>>({});

  const handleFetchParcel = async () => {
    if (!inputValue.trim()) {
      message.warning('Введіть ідентифікатор посилки!');
      return;
    }
    setLoading(true);
    try {
      const data = await parcelApiCall(inputValue);
      setParcelData(data);
      setEditedStatuses(data.statuses);
    } catch (error) {
      message.error('Не вдалося завантажити дані');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveStatuses = async () => {
    setSaving(true);

    const prepareStatusesForApi = editedStatuses.map(({ id, name, createdAt, comment }) => ({
      id,
      name,
      createdAt,
      comment
    }));

    try {
      await saveStatusesApiCall(prepareStatusesForApi);
      message.success('Статуси успішно збережені!');
    } catch (error) {
      message.error('Не вдалося зберегти статуси');
    } finally {
      setSaving(false);
    }
  };

  const handleStatusChange = (id: string, field: string, value: any) => {
    setEditedStatuses((prev) =>
      prev.map((status) => (status.id === id ? { ...status, [field]: value } : status))
    );
  };

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const statusData = await fetchParcelStatuses();
        setStatuses(statusData);
      } catch (error) {
        message.error('Не вдалося завантажити статуси');
      }
    };

    fetchStatuses();
  }, []);

  return (
    <List>
      <div style={{ marginBottom: 20 }}>
        <Input
          placeholder="Введіть ідентифікатор посилки"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ width: 300, marginRight: 10 }}
        />
        <Button type="primary" onClick={handleFetchParcel} loading={loading}>
          Отримати дані
        </Button>
      </div>

      {parcelData && (
        <>
          <h3>Деталі посилки</h3>
          <pre style={{ background: '#f6f8fa', padding: '10px', borderRadius: '5px' }}>
            {JSON.stringify(parcelData.parsel, null, 2)}
          </pre>
        </>
      )}

      {parcelData && (
        <>
          <h3>Історія статусів</h3>
          <Table
            dataSource={editedStatuses}
            rowKey="id"
            pagination={false}
            bordered
            columns={[
              { title: 'Роль', dataIndex: 'role', key: 'role' },
              {
                title: 'Статус',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => (
                  <Select
                    value={text}
                    onChange={(value) => handleStatusChange(record.id, 'name', value)}
                    style={{ width: '100%' }}>
                    {Object.entries(statuses).map(([key, label]) => (
                      <Option key={key} value={key}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                )
              },
              {
                title: 'Коментар',
                dataIndex: 'comment',
                key: 'comment',
                render: (text, record) => (
                  <Input
                    value={text}
                    onChange={(e) => handleStatusChange(record.id, 'comment', e.target.value)}
                  />
                )
              },
              {
                title: 'Створено',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (text, record) => (
                  <DatePicker
                    value={dayjs(text)}
                    onChange={(date) =>
                      handleStatusChange(record.id, 'createdAt', date?.toISOString())
                    }
                    showTime
                  />
                )
              }
            ]}
          />
          <Button
            type="primary"
            onClick={handleSaveStatuses}
            loading={saving}
            style={{ marginTop: 20 }}>
            Зберегти статуси
          </Button>
        </>
      )}
    </List>
  );
};
