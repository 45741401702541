import dayjs, { Dayjs } from 'dayjs';

export const MAX_PERIOD_MONTHS = 3;

export const validateDateRange = (_: unknown, value: Dayjs[] | undefined): Promise<void> => {
  if (!value || value.length !== 2) {
    return Promise.reject(new Error('Період має містити дві дати'));
  }

  const [startDate, endDate] = value;

  if (endDate.diff(startDate, 'months', true) > MAX_PERIOD_MONTHS) {
    return Promise.reject(
      new Error(`Максимальний період становить ${MAX_PERIOD_MONTHS} календарні місяці`)
    );
  }

  return Promise.resolve();
};

export const disabledDate = (current: Dayjs | null): boolean => {
  if (!current) return false;

  const threeYearsAgo = dayjs().subtract(3, 'years').startOf('day');
  const today = dayjs().endOf('day');

  return current.isAfter(today) || current.isBefore(threeYearsAgo);
};
