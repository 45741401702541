import { EApiRoutes } from '../../types/api-routes';
import { http } from '../axios';

export const getParcelStatuses = async () => {
  return await http.get(EApiRoutes.ParcelStatuses).then((res) => {
    const formatStatus = {
      data: res.data.map((item: string[]) => {
        return { id: item[0], title: item[1] };
      }),
      total: res.data.length
    };
    return formatStatus;
  });
};
