import type {
  AuthActionResponse,
  AuthProvider,
  CheckResponse,
  OnErrorResponse
} from '@refinedev/core';

import { isAuthenticated, login, logout } from '@frontend-auth';

export const authProvider: AuthProvider = {
  login: async (): Promise<AuthActionResponse> => {
    const response = await login();

    return {
      success: response.status === 'ok',
      redirectTo: response.status === 'ok' ? '/filials' : '/error'
    };
  },
  logout: async (): Promise<AuthActionResponse> => {
    const response = await logout();

    return {
      success: response.status === 'ok',
      redirectTo: response.status === 'ok' ? '/' : '/error'
    };
  },
  check: async (): Promise<CheckResponse> => {
    const authenticated = await isAuthenticated();

    return {
      authenticated,
      redirectTo: '/',
      logout: !authenticated
    };
  },
  onError: async (error): Promise<OnErrorResponse> => {
    console.error(error);
    return { error };
  }
};
