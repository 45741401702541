import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { SaveButton, useTable } from '@refinedev/antd';
import { useNotification, useTranslate } from '@refinedev/core';
import { FilialFullType } from '@shared-types';
import { Button, Card, Col, Form, Input, Row, Space, Table, Tag } from 'antd';
import debounce from 'lodash/debounce';
import { FC, useContext, useMemo } from 'react';
import { ContragentContext } from '../../../context/contragent-context';
import { SearchNoData } from '../../search-no-data';

export const ActionButtons: FC<{ record: FilialFullType }> = ({ record }) => {
  const { addFilial, removeFilial, filialsList, setIsContragentFilialsFormTouched } =
    useContext(ContragentContext);

  const translate = useTranslate();

  const { open } = useNotification();

  return !filialsList.some((filial) => record.id === filial.id) ? (
    <SaveButton
      icon={<PlusOutlined />}
      onClick={() => {
        setIsContragentFilialsFormTouched(true);
        const isAdded = addFilial({ id: record.id, name: record.name, toRemove: false });
        if (!isAdded) {
          open?.({
            message: translate('notifications.filials.alreadyAdded'),
            type: 'error'
          });
        }
      }}>
      Додати
    </SaveButton>
  ) : (
    <Button
      icon={<DeleteOutlined />}
      danger={true}
      onClick={(event) => {
        setIsContragentFilialsFormTouched(true);
        const isRemoved = removeFilial(record.id);
        if (isRemoved) {
          open?.({
            description: translate('notifications.success'),
            message: translate('notifications.filials.deleteSuccess'),
            type: 'success'
          });
        }
      }}>
      Видалити
    </Button>
  );
};

export const ContragentFiliaForm: FC<{ isEdit: boolean }> = ({ isEdit }) => {
  const { tableProps, setFilters } = useTable({
    resource: isEdit ? 'filialsByContragent' : 'filials',
    pagination: { mode: 'server', pageSize: 5, current: 1 }
  });

  const { removeFilial, filialsList } = useContext(ContragentContext);

  const translate = useTranslate();

  const { open } = useNotification();

  const onSearchHandler = useMemo(() => {
    return debounce((event) => {
      if ('value' in event.target) {
        if (event.target.value === '') {
          setFilters([], 'replace');
        } else {
          setFilters([
            {
              field: 'name',
              operator: 'contains',
              value: event.target.value
            }
          ]);
        }
      }
    }, 500);
  }, [setFilters]);

  return (
    <Row gutter={16}>
      <Col span={16}>
        <Card>
          <Space>
            <Form.Item name="nameSearch">
              <Input
                placeholder={translate('pages.contragents.list.columns.searchPlaceholder')}
                onInput={onSearchHandler}
              />
            </Form.Item>
          </Space>
          <Table
            size={'small'}
            {...tableProps}
            rowKey="id"
            locale={{ emptyText: <SearchNoData /> }}>
            <Table.Column
              dataIndex="name"
              title={translate('pages.contragents.list.columns.name')}
            />
            <Table.Column
              dataIndex="actions"
              title={translate('pages.contragents.list.columns.actions')}
              width={200}
              render={(_, record: FilialFullType) => {
                return <ActionButtons record={record} />;
              }}
            />
          </Table>
        </Card>
      </Col>
      <Col span={8}>
        <Card title={translate('pages.contragents.filials.title')}>
          <Space style={{ display: 'flex', maxWidth: '100%', flexWrap: 'wrap' }}>
            {filialsList.map((filial) => {
              if (filial.toRemove) {
                return null;
              }
              return (
                <Tag
                  color={'success'}
                  key={filial.id}
                  closable
                  onClose={() => {
                    removeFilial(filial.id);
                    open?.({
                      description: translate('notifications.success'),
                      message: translate('notifications.filials.deleteSuccess'),
                      type: 'success'
                    });
                  }}>
                  {filial.name}
                </Tag>
              );
            })}
          </Space>
        </Card>
      </Col>
    </Row>
  );
};
