import { Authenticated, I18nProvider, Refine } from '@refinedev/core';

import {
  ErrorComponent,
  ThemedLayoutV2,
  ThemedSiderV2,
  useNotificationProvider
} from '@refinedev/antd';
import '@refinedev/antd/dist/reset.css';

import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  UnsavedChangesNotifier
} from '@refinedev/react-router-v6';
import { ConfigProvider } from 'antd';
import uk_UA from 'antd/es/locale/uk_UA';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Header, Redirect } from './components';
import { ContragentContextProvider } from './context/contragent-context';
import { ContragentCreate, ContragentEdit, ContragentsList } from './pages/contagents';
import { FilialCreate, FilialEdit, FilialShow, FilialsList } from './pages/filials';
import { ParcelConfigStatusesList } from './pages/parcel-config-statuses';
import { ParcelListForDev } from './pages/parcel-dev';
import { ParcelInfoList } from './pages/parcel-info';
import { ProfileEdit } from './pages/profile';
import { authProvider } from './providers/authProvider';
import { dataProvider } from './providers/data-provider';
import { EResources } from './resources';

export const App: FC = () => {
  const { i18n, t } = useTranslation();

  const i18nProvider: I18nProvider = {
    /* eslint-disable-next-line */
    // @ts-ignore
    translate: (key: string, options?: any) => t(key, options),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language
  };

  return (
    <ConfigProvider locale={uk_UA}>
      <BrowserRouter>
        <Refine
          dataProvider={dataProvider}
          notificationProvider={useNotificationProvider}
          routerProvider={routerBindings}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          resources={EResources}
          options={{
            syncWithLocation: true,
            warnWhenUnsavedChanges: true
          }}>
          <Routes>
            <Route
              element={
                <Authenticated
                  key="authenticated-routes"
                  fallback={<CatchAllNavigate to="/login" />}
                  v3LegacyAuthProviderCompatible>
                  <ThemedLayoutV2 Header={Header} Sider={ThemedSiderV2}>
                    <Outlet />
                  </ThemedLayoutV2>
                </Authenticated>
              }>
              <Route path={'/'} element={<Redirect to={'/filials'} />} />
              <Route path="profile/edit/*" element={<ProfileEdit />} />
              <Route path="/filials">
                <Route index element={<FilialsList />} />
                <Route path="create/" element={<FilialCreate />} />
                <Route path="edit/:id" element={<FilialEdit />} />
                <Route path="show/:id" element={<FilialShow />} />
              </Route>
              <Route path="/contragents">
                <Route index element={<ContragentsList />} />
                <Route
                  path="create/"
                  element={
                    <ContragentContextProvider>
                      <ContragentCreate />
                    </ContragentContextProvider>
                  }
                />
                <Route
                  path="edit/:id"
                  element={
                    <ContragentContextProvider>
                      <ContragentEdit />
                    </ContragentContextProvider>
                  }
                />
              </Route>
              <Route path="parcel">
                <Route index element={<Navigate to="info" replace />} />
                <Route path="info">
                  <Route index element={<ParcelInfoList />} />
                </Route>
                <Route path="config-statuses">
                  <Route index element={<ParcelConfigStatusesList />} />
                </Route>
              </Route>
              <Route path="/parcel-dev">
                <Route index element={<ParcelListForDev />} />
              </Route>
            </Route>
            <Route
              element={
                <Authenticated key="catch-all" v3LegacyAuthProviderCompatible>
                  <ThemedLayoutV2 Header={Header}>
                    <Outlet />
                  </ThemedLayoutV2>
                </Authenticated>
              }>
              <Route path="*" element={<ErrorComponent />} />
            </Route>
          </Routes>

          <UnsavedChangesNotifier message="Ви впевнені, що хочете піти? У вас є незбережені зміни." />
          <DocumentTitleHandler />
        </Refine>
      </BrowserRouter>
    </ConfigProvider>
  );
};
