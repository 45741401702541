import { createRoot } from 'react-dom/client';

import { callback, checkAuthCookies } from '@frontend-auth';
import { App } from './App';
import './utils/i18n/i18n';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

function getCode(): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  return code;
}

const code = getCode();

if (code) {
  window.history.replaceState(null, '', window.location.pathname);
  callback(code).then((response) => {
    if (response.status === 'ok') {
      root.render(<App />);
    } else {
      root.render(
        <div>
          <h1>Something went wrong</h1>
          <p>{response.message}</p>
        </div>
      );
    }
  });
} else {
  checkAuthCookies().then((error) => {
    if (error) {
      root.render(
        <div>
          <h1>Something went wrong</h1>
          <p>{error}</p>
        </div>
      );
    } else {
      root.render(<App />);
    }
  });
}
