import { GetListParams } from '@refinedev/core';
import { EApiRoutes } from '../../types/api-routes';
import { http } from '../axios';

export const getFilialsByContragent = async (params: GetListParams) => {
  const requestParams: any = {};

  if (params.pagination) {
    requestParams.page = params.pagination.current;
    requestParams.pageSize = params.pagination.pageSize;
  }

  if (params.filters && params.filters.length > 0) {
    // eslint-disable-next-line
    // @ts-ignore
    requestParams.filterBy = params.filters[0].field;
    requestParams.filter = params.filters[0].value;
  }

  return await http
    .get(EApiRoutes.FilialsByContragent + params.meta?.id, { params: requestParams })
    .then((res) => {
      return res.data;
    });
};
