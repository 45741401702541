import { StreetType } from '@shared-types';
import { Form, Select } from 'antd';
import type { FC } from 'react';

type SelectStreetTypeProps = {
  options: ReadonlyArray<StreetType>;
};

export const SelectStreetType: FC<SelectStreetTypeProps> = ({ options }) => {
  return (
    <Form.Item
      style={{ marginBottom: 0, width: '100px', height: '30px' }}
      name={['address', 'type']}>
      <Select
        options={options.map(({ label, value }) => ({
          value,
          label: label.charAt(0).toUpperCase() + label.slice(1)
        }))}
        defaultActiveFirstOption={true}
      />
    </Form.Item>
  );
};
