import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { useInvalidate, useTranslate } from '@refinedev/core';
import { Button, Popconfirm, message } from 'antd';
import { FC, useState } from 'react';
import { manageFilial } from '../../../api/filials';

export enum FilialStatus {
  blocked = 'blocked',
  active = 'active'
}

type BlockFilialProps = {
  id: string;
  status: string;
};

export const BlockFilial: FC<BlockFilialProps> = ({ id, status }) => {
  const invalidate = useInvalidate();
  const translate = useTranslate();
  const isBlocked = status === FilialStatus.blocked;
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleBlockConfirm = async (recordId: string, status: string) => {
    setConfirmLoading(true);

    await manageFilial(
      recordId,
      status === FilialStatus.active ? FilialStatus.blocked : FilialStatus.active
    );

    setOpen(false);
    setConfirmLoading(false);

    const messageText =
      {
        [FilialStatus.active]: translate('notifications.filials.blockSuccess'),
        [FilialStatus.blocked]: translate('notifications.filials.unblockSuccess')
      }[status] ?? '';

    message.success(messageText);

    await invalidate({
      resource: 'filials',
      invalidates: ['list']
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Popconfirm
      title=""
      description={
        isBlocked ? (
          <p>{translate('popConfirm.filials.unblockTitle')}</p>
        ) : (
          <>
            <p>{translate('popConfirm.filials.blockTitle')}</p>
            <p>{translate('popConfirm.filials.blockContent')}</p>
          </>
        )
      }
      open={open}
      onConfirm={() => handleBlockConfirm(id, status)}
      okButtonProps={{ loading: confirmLoading }}
      okText={translate('popConfirm.okText')}
      cancelText={translate('popConfirm.cancelText')}
      onCancel={handleCancel}>
      <Button
        title={isBlocked ? translate('buttons.unblock') : translate('buttons.block')}
        size="small"
        icon={isBlocked ? <StopOutlined /> : <CheckCircleOutlined />}
        onClick={showPopconfirm}
      />
    </Popconfirm>
  );
};
