import { message } from 'antd';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

//TODO: add type
export const exportToExcel = async (data: any[], fileName: string): Promise<void> => {
  try {
    message.loading({ content: 'Експорт у процесі...', key: 'export', duration: 0 });

    if (!data || data.length === 0) {
      message.warning('Немає даних для експорту');
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Parcel Info');

    sheet.columns = [
      { header: 'Контрагент', key: 'contragent', width: 20 },
      { header: 'Місто', key: 'city', width: 20 },
      { header: 'ID пункту видачі', key: 'filialId', width: 15 },
      { header: 'Назва пункту видачі', key: 'filialName', width: 25 },
      { header: 'Номер замовлення', key: 'orderId', width: 15 },
      { header: 'Номер посилки', key: 'parcelId', width: 15 },
      { header: 'Статус', key: 'status', width: 15 },
      { header: 'Дата створення', key: 'createdAt', width: 15 },
      { header: 'Дата зміни статусу', key: 'dateChangeStatus', width: 15 },
      { header: 'Телефон отримувача', key: 'guestPhone', width: 15 }
    ];

    data.forEach((row: any) => {
      sheet.addRow({
        contragent: row.contragent?.name || '',
        city: row.filial?.addressCity || '',
        filialId: row.filial?.id || '',
        filialName: row.filial?.name || '',
        orderId: row.orderId || '',
        parcelId: row.parcelId || '',
        status: row.status || '',
        createdAt: row.createdAt ? dayjs(row.createdAt).format('DD.MM.YYYY') : '',
        dateChangeStatus: row.dateChangeStatus
          ? dayjs(row.dateChangeStatus).format('DD.MM.YYYY')
          : '',
        guestPhone: row.guestPhone || ''
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`;
    link.click();
    URL.revokeObjectURL(link.href);

    message.success({ content: 'Експорт завершено!', key: 'export' });
  } catch (error) {
    console.error('Помилка експорту:', error);
    message.error({ content: 'Помилка експорту', key: 'export' });
  }
};
