import { AppstoreOutlined, StarOutlined, UserOutlined } from '@ant-design/icons';
import { ResourceProps } from '@refinedev/core';

export const EResources: ResourceProps[] = [
  {
    name: 'filials',
    list: '/filials',
    create: '/filials/create',
    edit: '/filials/edit/:id',
    show: '/filials/show/:id',
    meta: {
      canDelete: false,
      icon: <AppstoreOutlined />,
      label: 'Точки видачі'
    }
  },
  {
    name: 'contragents',
    list: '/contragents',
    create: '/contragents/create',
    edit: '/contragents/edit/:id',
    meta: {
      canDelete: false,
      icon: <UserOutlined />,
      label: 'Контрагенти'
    }
  },
  {
    name: 'profile',
    edit: '/profile/edit/:id',
    meta: {
      canDelete: false,
      icon: <UserOutlined />,
      label: 'Профіль'
    }
  },
  {
    name: 'parcel',
    list: '/parcel',
    meta: { icon: <StarOutlined />, label: 'Посилки' }
  },
  {
    name: 'parcelInfo',
    list: '/parcel/info',
    meta: { parent: 'parcel', label: 'Інформація про посилки', hide: true }
  },
  {
    name: 'parcelConfigStatuses',
    list: '/parcel/config-statuses',
    meta: { parent: 'parcel', label: 'Керування статусами', hide: true }
  },
  {
    name: 'parcel-dev',
    list: '/parcel-dev',
    meta: {
      canDelete: false,
      label: 'Посилки (for dev)'
    }
  }
];
