import { Create, SaveButton, useForm } from '@refinedev/antd';
import { SaveButtonProps } from '@refinedev/antd/src/components/buttons/types';
import { useResource, useTranslate } from '@refinedev/core';
import { Form } from 'antd';
import { FC, memo } from 'react';
import { FilialForm, onFinishCollectData } from '../../components/forms';

const FooterButtons = memo(
  (
    props: {
      saveButtonProps?: SaveButtonProps;
    } & Record<string, unknown>
  ) => {
    const { saveButtonProps } = props;
    return (
      <SaveButton {...saveButtonProps} type="primary" style={{ marginRight: 8 }}>
        Зберегти
      </SaveButton>
    );
  }
);

export const FilialCreate: FC = () => {
  const translate = useTranslate();
  const { id } = useResource();
  const { formProps, form, saveButtonProps, onFinish } = useForm({
    resource: 'filials',
    id,
    successNotification: {
      type: 'success',
      message: translate('notifications.filials.createSuccess')
    },
    errorNotification: (error) => {
      let notificationMessage = '';
      if (error?.response?.data.message.includes('body/schedule')) {
        notificationMessage = translate('notifications.filials.anyScheduleError');
      } else if (error?.response?.data.message.includes('Exceeded daily email limit')) {
        notificationMessage = 'notifications.filials.Exceeded.daily.email.limit';
      } else {
        notificationMessage =
          'notifications.filials.' + error?.response?.data.message.replace(/\s/g, '.');
      }
      return {
        type: 'error',
        description: translate('notifications.error'),
        message: translate(notificationMessage)
      };
    }
  });

  const onFinishHandler = (values: any) => {
    onFinish(onFinishCollectData(values));
  };

  return (
    <Create
      title={translate('pages.filials.create.title')}
      breadcrumb={''}
      footerButtons={<FooterButtons saveButtonProps={saveButtonProps} />}>
      <Form
        {...formProps}
        initialValues={{ address: { type: 'street' } }}
        labelCol={{ flex: '150px' }}
        labelAlign={'left'}
        wrapperCol={{ flex: 1 }}
        form={form}
        onFinish={onFinishHandler}>
        <FilialForm />
      </Form>
    </Create>
  );
};
