import { Show } from '@refinedev/antd';
import { IResourceComponentsProps } from '@refinedev/core';
import { AntdShowInferencer } from '@refinedev/inferencer/antd';
import { FC } from 'react';

export const FilialShow: FC<IResourceComponentsProps> = (props, context) => {
  return (
    <Show>
      <AntdShowInferencer />
    </Show>
  );
};
