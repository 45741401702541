import { CreateButton, EditButton, useTable } from '@refinedev/antd';
import { IResourceComponentsProps, useTranslate } from '@refinedev/core';
import { FilialFullType } from '@shared-types';
import { Input, List, Space, Table, Tag, Typography } from 'antd';
import debounce from 'lodash/debounce';
import { FC, useMemo } from 'react';
import { SearchNoData } from '../../components/search-no-data';
import { useStreetTypes } from '../../hooks';
import { BlockFilial, DeleteFilialButton } from './components';
import styles from './list.module.css';

export const FilialsList: FC<IResourceComponentsProps> = () => {
  const { renderStreetType } = useStreetTypes();
  const { tableProps, setFilters } = useTable({
    resource: 'filials',
    syncWithLocation: true
  });
  const translate = useTranslate();

  const onSearchHandler = useMemo(() => {
    return debounce((event) => {
      if ('value' in event.target) {
        if (event.target.value === '') {
          setFilters([], 'replace');
        } else {
          setFilters([
            {
              field: 'name',
              operator: 'contains',
              value: event.target.value
            }
          ]);
        }
      }
    }, 500);
  }, [setFilters]);

  return (
    <List>
      <Space
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingBottom: 16
        }}>
        <CreateButton>{translate('buttons.create')}</CreateButton>
      </Space>
      <Table
        {...tableProps}
        locale={{ emptyText: <SearchNoData /> }}
        rowClassName={(record) => (record.status === 'blocked' ? styles.disabledRow : '')}
        rowKey="id">
        <Table.Column
          dataIndex="name"
          title={
            <Space>
              <Typography.Text strong>
                {translate('pages.filials.list.columns.name')}
              </Typography.Text>
              <Input
                size={'middle'}
                placeholder={translate('pages.contragents.list.columns.searchPlaceholder')}
                onInput={onSearchHandler}
              />
            </Space>
          }
        />
        <Table.Column
          dataIndex="address"
          title={translate('pages.filials.list.columns.address')}
          render={(_, record: FilialFullType) => {
            const {
              address: { city, street, house, type }
            } = record;

            return `м. ${city}, ${renderStreetType(type)}. ${street}, ${house}`;
          }}
        />
        <Table.Column
          dataIndex="status"
          title={translate('pages.filials.list.columns.status')}
          render={(_, record: FilialFullType) => {
            const isActive = record.status === 'active';
            const statuses = {
              active: translate('pages.filials.list.status.active'),
              blocked: translate('pages.filials.list.status.blocked')
            } as Partial<Record<string, string>>;

            const status = statuses[record.status] ?? record.status;

            return <Tag color={isActive ? 'success' : 'default'}>{status}</Tag>;
          }}
        />
        <Table.Column dataIndex="phone" title={translate('pages.filials.list.columns.phone')} />
        <Table.Column
          title={translate('pages.filials.list.columns.actions')}
          dataIndex="actions"
          render={(_, record: FilialFullType) => {
            const { id, status } = record;
            return (
              <Space>
                <EditButton
                  title={translate('buttons.edit')}
                  hideText
                  size="small"
                  recordItemId={id}
                />

                <BlockFilial id={id} status={status} />

                <DeleteFilialButton record={record} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
