import { BaseOption, useTranslate } from '@refinedev/core';
import { Button, Col, DatePicker, Form, FormProps, Row, Select } from 'antd';
import { FC } from 'react';
import { IParcelInfoFilterVariables } from '../../../types/parcel-info';
import { disabledDate, validateDateRange } from './parcel-info.utils';

const { RangePicker } = DatePicker;

interface FilterFormProps {
  contragentsOptions: BaseOption[];
  parcelStatusesOptions: BaseOption[];
  searchFormProps: FormProps<IParcelInfoFilterVariables>;
}

export const ParcelInfoFilterForm: FC<FilterFormProps> = ({
  contragentsOptions,
  parcelStatusesOptions,
  searchFormProps
}) => {
  const translate = useTranslate();

  return (
    <Form layout="vertical" {...searchFormProps}>
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <Form.Item
            label={translate('pages.parcelInfo.list.filter.contragent')}
            name="contragent"
            rules={[
              { required: true, message: translate('notifications.parcelInfo.tryContragent') }
            ]}>
            <Select
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              options={contragentsOptions}
              placeholder={translate('pages.parcelInfo.list.filter.contragent')}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            label={translate('pages.parcelInfo.list.filter.createdAt')}
            name="createdAt"
            rules={[
              { required: true, message: translate('notifications.parcelInfo.tryCreatedAt') },
              { validator: validateDateRange }
            ]}>
            <RangePicker style={{ width: '100%' }} disabledDate={disabledDate} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label={translate('pages.parcelInfo.list.filter.status')} name="status">
            <Select
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={translate('pages.parcelInfo.list.filter.status')}
              options={parcelStatusesOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          {translate('buttons.apply')}
        </Button>
      </Form.Item>
    </Form>
  );
};
