import { StreetType } from '@shared-types';
import { EApiRoutes } from '../../types/api-routes';
import { http } from '../axios';

export type GetAddressTypesResponse = {
  data: StreetType[];
  total: number;
};

export const getStreetTypes = async (): Promise<StreetType[]> => {
  try {
    const {
      data: { data }
    } = await http.get<GetAddressTypesResponse>(EApiRoutes.Dictionaries);

    return data;
  } catch (error) {
    console.log(error);
  }
  return [];
};
