import { Create, ListButton, SaveButton, useForm } from '@refinedev/antd';
import { SaveButtonProps } from '@refinedev/antd/src/components/buttons/types';
import { useTranslate } from '@refinedev/core';
import { Form, Space, Tabs, TabsProps } from 'antd';
import { FC, memo, useCallback, useContext, useMemo } from 'react';
import { ContragentCommonForm, ContragentFiliaForm } from '../../components/forms';
import { ContragentContext } from '../../context/contragent-context';

const FooterButtons = memo(
  (
    props: {
      saveButtonProps?: SaveButtonProps;
      checkRequiredInputs: boolean;
    } & Record<string, unknown>
  ) => {
    const { saveButtonProps, checkRequiredInputs } = props;
    return (
      <Space>
        <ListButton />
        <SaveButton
          {...saveButtonProps}
          type="primary"
          style={{ marginRight: 8 }}
          disabled={!checkRequiredInputs}>
          Створити
        </SaveButton>
      </Space>
    );
  }
);

export const ContragentCreate: FC = () => {
  const translate = useTranslate();
  const { saveButtonProps, formProps, form, onFinish } = useForm({
    resource: 'contragents',
    redirect: 'list',
    successNotification: {
      type: 'success',
      description: translate('notifications.success'),
      message: translate('notifications.contragents.createSuccess')
    },
    errorNotification: (error) => {
      return {
        type: 'error',
        description: translate('notifications.error'),
        message: translate(
          'notifications.contragents.' + error?.response?.data.message.replace(/\s/g, '.')
        )
      };
    }
  });

  const { filialsList } = useContext(ContragentContext);

  const nameInput = Form.useWatch('name', form);
  const phoneInput = Form.useWatch('phone', form);

  const onFinishHandler = useCallback(
    (values: any) => {
      const res = {
        name: values.name,
        email: values.email,
        site: values.site,
        phone: values.phone,
        address: values.address,
        expirationDays: values.expirationDays,
        filials: filialsList.map((item) => item.id)
      };
      onFinish(res);
    },
    [filialsList, onFinish]
  );

  const checkRequiredInputs = useMemo(() => {
    return nameInput?.length > 0 && phoneInput?.length === 12;
  }, [nameInput?.length, phoneInput?.length]);

  const items: TabsProps['items'] = useMemo(() => {
    return [
      {
        key: '0',
        label: translate('formInputs.tabs.general'),
        children: <ContragentCommonForm />
      },
      {
        key: '1',
        label: translate('formInputs.tabs.filials'),
        children: <ContragentFiliaForm isEdit={false} />,
        disabled: !checkRequiredInputs
      }
    ];
  }, [checkRequiredInputs, translate]);

  return (
    <Create
      title={translate('pages.contragents.create.title')}
      breadcrumb={''}
      footerButtons={
        <FooterButtons
          saveButtonProps={saveButtonProps}
          checkRequiredInputs={checkRequiredInputs}
        />
      }>
      <Form
        {...formProps}
        initialValues={{ address: { type: 'street' } }}
        labelCol={{ flex: '150px' }}
        labelAlign={'left'}
        wrapperCol={{ flex: 1 }}
        form={form}
        onFinish={onFinishHandler}>
        <Tabs items={items} />
      </Form>
    </Create>
  );
};
