import { DeleteButton } from '@refinedev/antd';
import { useTranslate } from '@refinedev/core';
import { FilialFullType } from '@shared-types';
import { FC, memo } from 'react';

type DeleteFilialButtonProps = {
  record: FilialFullType;
};
const DeleteConfirmMessage = memo(() => {
  const translate = useTranslate();
  return (
    <>
      <p>{translate('popConfirm.filials.deleteConfirmMessage.row1')}</p>
      <p>{translate('popConfirm.filials.deleteConfirmMessage.row2')}</p>
      <p>{translate('popConfirm.filials.deleteConfirmMessage.row3')}</p>
    </>
  );
});

export const DeleteFilialButton: FC<DeleteFilialButtonProps> = ({ record }) => {
  const translate = useTranslate();
  return (
    <DeleteButton
      hideText
      size={'small'}
      recordItemId={record.id}
      confirmTitle={(<DeleteConfirmMessage />) as unknown as string}
      confirmOkText={translate('popConfirm.okText')}
      confirmCancelText={translate('popConfirm.cancelText')}
      successNotification={{
        description: translate('notifications.success'),
        message: translate('notifications.filials.deleteSuccess'),
        type: 'success'
      }}
      errorNotification={{
        description: translate('notifications.error'),
        message: translate('notifications.filials.deleteError'),
        type: 'error'
      }}
    />
  );
};
