import { Edit, ListButton, SaveButton, useForm } from '@refinedev/antd';
import { SaveButtonProps } from '@refinedev/antd/src/components/buttons/types';
import { useResource, useTranslate } from '@refinedev/core';
import { Form, Space } from 'antd';
import { FC, memo } from 'react';
import { FilialForm, onFinishCollectData } from '../../components/forms';

const FooterButtons = memo(
  (
    props: {
      saveButtonProps?: SaveButtonProps;
    } & Record<string, unknown>
  ) => {
    const translate = useTranslate();
    const { saveButtonProps } = props;
    return (
      <Space>
        <ListButton>{translate('buttons.toFilialsList')}</ListButton>
        <SaveButton {...saveButtonProps} type="primary" style={{ marginRight: 8 }}>
          {translate('buttons.save')}
        </SaveButton>
      </Space>
    );
  }
);

export const FilialEdit: FC = () => {
  const translate = useTranslate();
  const { id } = useResource();
  const { saveButtonProps, formProps, form, onFinish } = useForm({
    resource: 'filials',
    id,
    successNotification: {
      type: 'success',
      message: translate('notifications.filials.editSuccess'),
      description: translate('notifications.success')
    },
    errorNotification: (error) => {
      let notificationMessage = '';
      if (error?.response?.data.message.includes('body/schedule')) {
        notificationMessage = translate('notifications.filials.anyScheduleError');
      } else {
        notificationMessage =
          'notifications.filials.' + error?.response?.data.message.replace(/\s/g, '.');
      }
      return {
        type: 'error',
        description: translate('notifications.error'),
        message: translate(notificationMessage)
      };
    }
  });

  const onFinishHandler = (values: any) => {
    onFinish(onFinishCollectData(values));
  };

  return (
    <Edit
      title={translate('pages.filials.edit.title')}
      breadcrumb={''}
      headerButtons={() => null}
      footerButtons={<FooterButtons saveButtonProps={saveButtonProps} />}>
      <Form
        {...formProps}
        initialValues={formProps.initialValues}
        labelCol={{ flex: '150px' }}
        labelAlign={'left'}
        wrapperCol={{ flex: 1 }}
        form={form}
        onFinish={onFinishHandler}>
        <FilialForm />
      </Form>
    </Edit>
  );
};
