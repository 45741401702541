import { FilialStatus } from '../../pages/filials/components/block-filial';
import { EApiRoutes } from '../../types/api-routes';
import { http } from '../axios';

export async function manageFilial(id: string, status: FilialStatus) {
  try {
    const { data } = await http.put(`${EApiRoutes.Filials}${id}`, { status });
    return data;
  } catch (e) {
    console.log(e);
  }
}
