// Порядковий номер рядка;
// Контрагент - назва контрагента;
// Місто - назва міста пункту видачі;
// ID пункту видачі - внутрішній ідентифікатор пункту видачі (за наявності);
// Назва - пункту видачі - назва пункту видачі;
// Номер замовлення - orderId
// Номер посилки - parcelId
// Статус - статус посилки, в якому вона перебуває на момент відправки запиту;
// Дата створення - дата переходу посилки в статус Delivering;
// Дата зміни статусу - дата переходу посилки в статус, в якому вона перебуває на момент відправки запиту;
// Телефон отримувача - номер телефону Гостя;

//TODO: remove after
export const mookDataParcelInfo: any = {
  data: [
    {
      id: '2082060f-41c0-43d9-a5ca-a2540a6d4419',
      // name: 'Тестова точка видачі',
      createdAt: '2024-12-03T10:28:20.597Z',
      dateChangeStatus: '2024-12-03T10:28:20.597Z',
      guestPhone: '380862466978',
      status: 'delivering',
      parcelId: '3333',
      orderId: 'gdfgfgdfg',
      contragentId: '5fde1d14-5115-459a-8c8e-43b289cb57b4',
      contragent: {
        id: '2082460f-41c0-43d9-a5ca-a2540a6d4418',
        name: 'Test Conteragent'
      },
      filial: {
        id: '2082060f-41c0-43d9-a5ca-a2540a6d4418',
        addressCity: 'Вінниця',
        name: 'Тестова точка видачі'
      }
    },
    {
      id: '2082060f-41c0-43d9-a5ca-a2540a6d4418',
      // name: 'Тестова точка видачі',
      createdAt: '2024-12-03T10:28:20.597Z',
      dateChangeStatus: '2024-12-03T10:28:20.597Z',
      guestPhone: '380862466978',
      status: 'delivering',
      parcelId: '3333',
      orderId: 'gdfgfgdfg',
      contragentId: '5fde1d14-5115-439a-8c8e-423b289cb57b4',
      contragent: {
        id: '2082050f-41c0-43d9-a5ca-a2540a6d4418',
        name: 'Test Conteragent'
      },
      filial: {
        id: '2082060f-41c0-43d9-a5ca-a2x40a6d4418',
        addressCity: 'Вінниця',
        name: 'Тестова точка видачі'
      }
    },
    {
      id: '2082060f-41c0-43d9-a5ca-a2540a6d4417',
      // name: 'Тестова точка видачі',
      createdAt: '2024-12-03T10:28:20.597Z',
      dateChangeStatus: '2024-12-03T10:28:20.597Z',
      guestPhone: '380862466978',
      status: 'delivering',
      parcelId: '3333',
      orderId: 'gdfgfgdfg',
      contragentId: '5fde1d14-5115-439a-8c8e-43b259cb57b4',
      contragent: {
        id: '2082060f-41c0-43d9-a5ca-af540a6d4418',
        name: 'Test Conteragent'
      },
      filial: {
        id: '2082060f-41c0-43d9-a5ca-as540a6d4418',
        addressCity: 'Вінниця',
        name: 'Тестова точка видачі'
      }
    }
  ],
  total: 3
};
