import { useCallback, useState } from 'react';

export const useFilterDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openDrawer = useCallback(() => setIsOpen(true), []);
  const closeDrawer = useCallback(() => setIsOpen(false), []);

  return { isOpen, openDrawer, closeDrawer };
};
