import { GetListParams } from '@refinedev/core';
import { EApiRoutes } from '../../types/api-routes';
import { http } from '../axios';

export const getFilials = async (params: GetListParams) => {
  const requestParams: any = {};

  if (params.meta?.id) {
    requestParams.contragentId = params.meta?.id;
  }

  if (params.pagination) {
    requestParams.page = params.pagination.current;
    requestParams.pageSize = params.pagination.pageSize;
  }

  if (params.filters && params.filters.length > 0) {
    // eslint-disable-next-line
    // @ts-ignore
    requestParams.filterBy = params.filters[0].field;
    requestParams.filter = params.filters[0].value;
  }

  if (params.sorters && params.sorters.length > 0) {
    requestParams.orderBy = params.sorters[0].field;
    requestParams.order = params.sorters[0].order;
  }

  return await http.get(EApiRoutes.Filials, { params: requestParams }).then((res) => {
    return res.data;
  });
};
