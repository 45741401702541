import { useSelect, useTranslate } from '@refinedev/core';
import { AutoComplete, Form, Input, Switch } from 'antd';
import { FC, useState } from 'react';
import { useStreetTypes } from '../../../hooks';
import { commonInputRules } from '../../../utils/form-paterns/common';
import { phoneNumberRules } from '../../../utils/form-paterns/phone';
import { SelectStreetType } from '../select-street-type';

export const FilialForm: FC = () => {
  const translate = useTranslate();
  const { streetTypes } = useStreetTypes();
  const [inputValue, setInputValue] = useState<string>('');

  const { options: placeDescriptionLOptions } = useSelect({
    resource: 'dictionaries/contragents-list',
    filters: inputValue ? [{ field: 'search', operator: 'contains', value: inputValue }] : [],
    pagination: { pageSize: 10 },
    optionLabel: (item) => `${item.label}`,
    optionValue: (item) => `${item.value}`
  });

  const autoCompleteOptions = placeDescriptionLOptions.map((opt) => ({
    value: opt.value
  }));

  if (streetTypes.length === 0) {
    return null;
  }

  return (
    <>
      <Form.Item
        label={translate('formInputs.name')}
        name={'name'}
        rules={[commonInputRules.required, commonInputRules.nameUkrWithNumbers]}>
        <Input count={{ show: true, max: 50 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.isSilpoPoint')}
        name={'isSilpoPoint'}
        valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.shortId')}
        name={'shortId'}
        dependencies={['isSilpoPoint']}
        rules={[
          ({ getFieldValue }) => ({
            required: getFieldValue('isSilpoPoint'),
            message: translate('message.required')
          }),
          commonInputRules.onlyNumbers
        ]}>
        <Input count={{ show: true, max: 6 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.city')}
        name={['address', 'city']}
        rules={[commonInputRules.required, commonInputRules.nameUkr]}>
        <Input count={{ show: true, max: 50 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.region')}
        name={['address', 'region']}
        rules={[commonInputRules.required, commonInputRules.nameUkr]}>
        <Input count={{ show: true, max: 50 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.street')}
        name={['address', 'street']}
        rules={[commonInputRules.required, commonInputRules.street]}>
        <Input
          addonBefore={<SelectStreetType options={streetTypes} />}
          count={{ show: true, max: 200 }}
          allowClear
        />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.house')}
        name={['address', 'house']}
        rules={[commonInputRules.required, commonInputRules.numberOfBuild]}>
        <Input count={{ show: true, max: 10 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.phone')}
        name={'phone'}
        rules={[
          commonInputRules.required,
          phoneNumberRules.phone,
          phoneNumberRules.phoneMin,
          phoneNumberRules.phoneMax
        ]}>
        <Input count={{ show: true, max: 12 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.email')}
        name={'email'}
        rules={[commonInputRules.required, commonInputRules.email]}>
        <Input count={{ show: true, max: 50 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.placeDescription')}
        name={'placeDescription'}
        rules={[commonInputRules.allowedCharacters]}>
        <AutoComplete
          options={autoCompleteOptions}
          value={inputValue}
          onSearch={setInputValue}
          onChange={setInputValue}
          allowClear
          maxLength={100}
          filterOption={(input, option) => {
            if (typeof option?.value === 'string') {
              return option.value.toLowerCase().includes(input.toLowerCase());
            }
            return false;
          }}
        />
      </Form.Item>
      <Form.Item
        wrapperCol={{ span: 4 }}
        label={translate('formInputs.schedule.from')}
        name={['schedule', 'start']}
        rules={[commonInputRules.schedule]}>
        <Input count={{ max: 5 }} allowClear />
      </Form.Item>
      <Form.Item
        wrapperCol={{ span: 4 }}
        label={translate('formInputs.schedule.to')}
        name={['schedule', 'end']}
        rules={[commonInputRules.schedule]}>
        <Input count={{ max: 5 }} allowClear />
      </Form.Item>
    </>
  );
};
