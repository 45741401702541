export const enum EApiRoutes {
  Personas = 'personas/',
  Filials = 'filials/',
  FilialsByContragent = 'filials/contragent/',
  Contragents = 'contragents/',
  Dictionaries = 'dictionaries/',
  PlaceParcelDescr = '/dictionaries/place-description-list',
  Parcels = 'parcels',
  ParcelStatuses = 'fix/parcel-statuses'
}
