import { DataProvider } from '@refinedev/core';
import { AxiosError } from 'axios';
import i18n from 'i18next';
import { http } from '../api/axios';
import { getContragents } from '../api/contragents';
import { getPlaceDescriptionList, getStreetTypes } from '../api/dictionaries';
import { getFilials, getFilialsByContragent } from '../api/filials';
import { getParcelInfo, getParcelStatuses } from '../api/parcel';

type MethodTypes = 'get' | 'delete' | 'head' | 'options';
type MethodTypesWithBody = 'post' | 'put' | 'patch';

export const dataProvider: DataProvider = {
  getList: (params) => {
    switch (params.resource) {
      case 'filials':
        return getFilials(params);
      case 'filialsByContragent':
        return getFilialsByContragent(params);
      case 'streetTypes':
        return getStreetTypes();
      case 'contragents':
        return getContragents(params);
      case 'parcel-info':
        return getParcelInfo(params);
      case 'fix/parcel-statuses':
        return getParcelStatuses();
      case 'dictionaries/contragents-list':
        return getPlaceDescriptionList(params);
    }
    throw new Error('getList - Method not implemented.');
  },
  create: async ({ resource, variables, meta }) => {
    const url = `/${resource}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? 'post';

    const { data } = await http[requestMethod](url, variables, {
      headers
    });

    return {
      data
    };
  },
  update: async ({ resource, id, variables, meta }) => {
    const url = `/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? 'put';

    const { data } = await http[requestMethod](url, variables, {
      headers
    });

    return {
      data
    };
  },
  deleteOne: async ({ resource, id, variables, meta }) => {
    const url = `/${resource}/${id}`;
    const isFilial = resource === 'filials';

    const { method } = meta ?? {};
    const requestMethod = isFilial ? 'put' : ((method as MethodTypesWithBody) ?? 'delete');

    const { data } = await http[requestMethod](
      url,
      isFilial ? { ...variables, status: 'deleted' } : variables
    );

    return {
      data
    };
  },
  getOne: async ({ resource, id, meta }) => {
    const url = `/${resource}/${id}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? 'get';

    try {
      const { data } = await http[requestMethod](url, { headers });
      return { data };
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data?.statusCode >= 500) {
          throw new Error(i18n.t('notifications.500'));
        } else {
          throw e;
        }
      } else {
        throw e;
      }
    }
  },
  getApiUrl: () => '',
  // optional methods
  getMany: ({ resource, ids, meta }) => {
    throw new Error('getMany - Method not implemented.');
  },
  createMany: ({ resource, variables, meta }) => {
    throw new Error('createMany - Method not implemented.');
  },
  deleteMany: ({ resource, ids, variables, meta }) => {
    throw new Error('deleteMany - Method not implemented.');
  },
  updateMany: ({ resource, ids, variables, meta }) => {
    throw new Error('updateMany - Method not implemented.');
  },
  custom: ({ url, method, filters, sorters, payload, query, headers, meta }) => {
    throw new Error('custom - Method not implemented.');
  }
};
