import { useList } from '@refinedev/core';
import { StreetType } from '@shared-types';
import { useCallback } from 'react';

export type UseStreetTypes = {
  renderStreetType: (type: string) => string;
  readonly streetTypes: ReadonlyArray<StreetType>;
};

export const useStreetTypes = (): UseStreetTypes => {
  const { data = [] as StreetType[] } = useList<StreetType[]>({
    resource: 'streetTypes',
    queryOptions: {
      staleTime: Infinity
    }
  });

  const renderStreetType = useCallback(
    (type: string) => {
      const streetType = (data as StreetType[]).find((streetType) => streetType.value === type);

      return streetType?.label ?? '';
    },
    [data]
  );

  return {
    renderStreetType,
    streetTypes: data as StreetType[]
  };
};
