import { Edit, useForm } from '@refinedev/antd';
import { useTranslate } from '@refinedev/core';
import { Form, Input } from 'antd';
import { FC } from 'react';
import { IPersona } from '../../api/personas/types';
import { commonInputRules } from '../../utils/form-paterns/common';

export const ProfileEdit: FC = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, formLoading, form, onFinish } = useForm<IPersona>({
    resource: 'cognito-users',
    id: 'me',
    successNotification: {
      type: 'success',
      message: translate('notifications.profile.editSuccess'),
      description: translate('notifications.success')
    },
    errorNotification: {
      type: 'error',
      message: translate('notifications.profile.editError'),
      description: translate('notifications.error')
    },
    redirect: false
  });

  const onFinishHandler = (values: any) => {
    const res = {
      ...values,
      email: values.email.trim(),
      name: values.name.trim()
    };
    onFinish(res);
  };

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      breadcrumb={false}
      isLoading={formLoading}
      title={'Редагування профілю'}
      headerProps={{ extra: false }}>
      <Form {...formProps} layout="vertical" form={form} onFinish={onFinishHandler}>
        <Form.Item
          label={translate('formInputs.email')}
          name="email"
          rules={[commonInputRules.email, commonInputRules.required]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={translate('formInputs.userName')}
          name="name"
          rules={[commonInputRules.nameUkr, commonInputRules.required]}>
          <Input />
        </Form.Item>
        <Form.Item label={translate('formInputs.phone')} name="phone">
          <Input disabled />
        </Form.Item>
        <Form.Item label={'hidden filial ID'} name="filialId" hidden>
          <Input hidden />
        </Form.Item>
      </Form>
    </Edit>
  );
};
