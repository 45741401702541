import { Edit, ListButton, SaveButton, useForm } from '@refinedev/antd';
import { useGo, useResource, useTranslate } from '@refinedev/core';
import { Form, Tabs, TabsProps } from 'antd';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ContragentCommonForm, ContragentFiliaForm } from '../../components/forms';
import { ContragentContext } from '../../context/contragent-context';

export const ContragentEdit: FC = () => {
  const translate = useTranslate();
  const { id } = useResource();
  const { formProps, queryResult, onFinish, form, saveButtonProps } = useForm({
    resource: 'contragents',
    id,
    warnWhenUnsavedChanges: false,
    successNotification: {
      type: 'success',
      description: translate('notifications.success'),
      message: translate('notifications.contragents.editSuccess')
    },
    errorNotification: (error) => {
      return {
        type: 'error',
        description: translate('notifications.error'),
        message: translate(
          'notifications.contragents.' + error?.response?.data.message.replace(/\s/g, '.')
        )
      };
    }
  });
  const go = useGo();

  const { initFilials, filialsList, isContragentFilialsFormTouched } =
    useContext(ContragentContext);
  const [isGeneralFormTouched, setIsGeneralFormTouched] = useState(false);

  const items: TabsProps['items'] = useMemo(() => {
    return [
      {
        key: '0',
        label: translate('formInputs.tabs.general'),
        children: <ContragentCommonForm />
      },
      {
        key: '1',
        label: translate('formInputs.tabs.filials'),
        children: <ContragentFiliaForm isEdit={true} />
      }
    ];
  }, [translate]);

  const onFinishHandler = useCallback(
    async (values: any) => {
      const res = {
        name: values.name?.trim(), // required
        email: values.email?.trim() || null,
        site: values.site?.trim() || null,
        phone: values.phone?.trim(), // required
        address: {
          city: values.address.city?.trim() || null,
          region: values.address.region?.trim() || null,
          street: values.address.street?.trim() || null,
          house: values.address.house?.trim() || null
        },
        expirationDays: values.expirationDays || null,
        filials: filialsList.map((item) => item.id)
      };

      try {
        await onFinish(res);
      } catch (error) {
        console.log(`error`, error);
      }
    },
    [filialsList, onFinish]
  );

  useEffect(() => {
    if (formProps.initialValues) {
      initFilials(formProps.initialValues.filials);
    }
  }, [formProps.initialValues, initFilials]);

  const handleValuesChange = useCallback(() => {
    setIsGeneralFormTouched(true);
  }, [setIsGeneralFormTouched]);

  const handleLeaveUnsavedChanges = useCallback(() => {
    if (!isContragentFilialsFormTouched && !isGeneralFormTouched) {
      go({ to: '/contragents' });
      return;
    }

    const confirmNavigation = window.confirm(
      'Ви впевнені, що хочете піти? У вас є незбережені зміни.'
    );
    if (confirmNavigation) {
      go({ to: '/contragents' });
    }
  }, [go, isContragentFilialsFormTouched, isGeneralFormTouched]);

  return (
    <Edit
      title={'Редагування контрагента'}
      breadcrumb={''}
      headerButtons={() => null}
      headerProps={{ onBack: handleLeaveUnsavedChanges }}
      footerButtons={(context) => {
        return (
          <>
            {isContragentFilialsFormTouched || isGeneralFormTouched ? (
              <ListButton onClick={handleLeaveUnsavedChanges} />
            ) : (
              <ListButton />
            )}
            <SaveButton {...context.saveButtonProps} onSubmit={onFinishHandler}>
              Зберегти
            </SaveButton>
          </>
        );
      }}
      saveButtonProps={saveButtonProps}
      isLoading={queryResult?.isLoading}>
      <Form
        initialValues={formProps.initialValues}
        {...formProps}
        form={form}
        labelCol={{ flex: '150px' }}
        labelAlign={'left'}
        wrapperCol={{ flex: 1 }}
        onValuesChange={handleValuesChange}
        onFinish={onFinishHandler}>
        <Tabs items={items} />
      </Form>
    </Edit>
  );
};
