import { useTranslate } from '@refinedev/core';
import { Button, Col, Form, FormProps, Input, Row, Select } from 'antd';
import { FC } from 'react';
import { IParcelConfigFilterVariables } from '../../../types/parcel-config-statuses';

const { TextArea } = Input;

interface FilterFormProps {
  searchFormProps: FormProps<IParcelConfigFilterVariables>;
}
const searchByOptions = [
  { value: 'orderId', label: 'Номер замовлення' },
  { value: 'parcelId', label: 'Номер посилки' },
  { value: 'guestPhone', label: 'Телефон гостя' }
];

export const ParcelConfigStatusesFilterForm: FC<FilterFormProps> = ({ searchFormProps }) => {
  const translate = useTranslate();

  return (
    <Form layout="vertical" {...searchFormProps}>
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <Form.Item
            label={translate('pages.parcelConfigStatuses.filter.searchBy')}
            name="searchBy"
            rules={[
              {
                required: true
              }
            ]}>
            <Select
              allowClear
              style={{ width: '100%' }}
              options={searchByOptions}
              placeholder={translate('pages.parcelConfigStatuses.filter.searchBy')}
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label={translate('pages.parcelConfigStatuses.filter.dataForSearch')}
            name="dataForSearch"
            rules={[
              {
                required: true
              }
            ]}>
            <TextArea
              placeholder={translate('pages.parcelConfigStatuses.filter.dataForSearch')}
              autoSize={{ minRows: 5, maxRows: 10 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          {translate('buttons.apply')}
        </Button>
      </Form.Item>
    </Form>
  );
};
