import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigation, useTranslate } from '@refinedev/core';
import { Layout as AntdLayout, Dropdown, MenuProps, Space, theme } from 'antd';
import { FC, useMemo } from 'react';

import { logout } from '@frontend-auth';
import { MenuItemType } from 'antd/es/menu/interface';
const { useToken } = theme;

export const Header: FC = () => {
  const { token } = useToken();
  const { edit } = useNavigation();
  const translate = useTranslate();

  const settingsMenu: MenuItemType[] = useMemo(() => {
    return [
      { key: 1, label: translate('buttons.profile'), icon: <UserOutlined /> },
      { key: 2, label: translate('buttons.logout'), icon: <LogoutOutlined /> }
    ];
  }, [translate]);

  const onMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case '1':
        edit('profile', 'me');
        break;
      case '2':
        logout();
        break;
      default:
        return;
    }
  };

  return (
    <AntdLayout.Header
      style={{
        backgroundColor: token.colorBgElevated,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 24px',
        height: '64px'
      }}>
      <Space style={{ marginLeft: '8px' }} size="middle">
        <Dropdown.Button
          placement={'bottom'}
          menu={{ items: settingsMenu, onClick: onMenuClick }}
          onClick={() => {
            edit('profile', 'me');
          }}>
          <UserOutlined />
        </Dropdown.Button>
      </Space>
    </AntdLayout.Header>
  );
};
