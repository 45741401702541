import { FilialFullType } from '@shared-types';

export const onFinishCollectData = (formValues: any): Partial<FilialFullType> => {
  const res = {
    address: {
      city: formValues.address.city,
      region: formValues.address.region,
      type: formValues.address.type || 'street',
      street: formValues.address.street,
      house: formValues.address.house
    },
    name: formValues.name,
    phone: formValues.phone,
    email: formValues.email,
    schedule: formValues.schedule,
    isSilpoPoint: formValues.isSilpoPoint,
    shortId: formValues.shortId,
    placeDescription: formValues.placeDescription
  };

  if (!res.schedule.start && !res.schedule.end) {
    res.schedule.start = null;
    res.schedule.end = null;
  }
  return res;
};
