export const commonInputRules = {
  required: { required: true, message: "Обов'язкове поле" },
  name: {
    pattern: /^[\u0400-\u04FFA-Za-z\s'-]{1,50}$/,
    message:
      "Лише літери англійського та українського алфавіту, пробіл, та символи '-. Макс. 50 символів."
  },
  nameUkr: {
    pattern: /^[\u0400-\u04FF\s'-]{1,50}$/,
    message: "Лише літери українського алфавіту, пробіл, та символи '-. Макс. 50 символів."
  },
  nameUkrWithNumbers: {
    pattern: /^[\u0400-\u04FF\d\s'-]{1,50}$/,
    message: "Лише літери українського алфавіту, цифри, пробіл, та символи '-. Макс. 50 символів."
  },
  street: {
    pattern: /^[\u0400-\u04FF\d\s'-]{1,200}$/,
    message: "Лише літери українського алфавіту, цифри, пробіл та символи '-. Макс. 200 символів."
  },
  numberOfBuild: {
    pattern: /^[\u0400-\u04FF0-9/]*$/,
    message: 'Лише літери українського алфавіту, цифри та символ /'
  },
  email: {
    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/iu,
    message: 'Невірний формати пошти.'
  },
  schedule: {
    pattern: /^([01]?\d|2[0-3]):[0-5]\d$/,
    message: 'Необхідний формат часу - 00:00'
  },
  expirationDays: {
    pattern: /^[1-9]$|^1\d$|^2\d$|^30$/,
    message: 'Введіть число від 1 до 30'
  },
  onlyNumbers: {
    pattern: /^\d+$/,
    message: 'Допустимо лише цифри'
  },
  allowedCharacters: {
    pattern: /^[\u0400-\u04FF\d№#\-.,:;'()\s]*$/,
    message: "Допустимі лише літери українського алфавіту, цифри, спецсимволи (№ # -.,:;')"
  }
};
