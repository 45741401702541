import { useTranslate } from '@refinedev/core';
import { Card, Form, Input } from 'antd';
import { FC } from 'react';
import { useStreetTypes } from '../../../hooks';
import { commonInputRules } from '../../../utils/form-paterns/common';
import { phoneNumberRules } from '../../../utils/form-paterns/phone';
import { SelectStreetType } from '../select-street-type';

export const ContragentCommonForm: FC = () => {
  const translate = useTranslate();
  const { streetTypes } = useStreetTypes();
  if (streetTypes.length === 0) {
    return null;
  }
  return (
    <Card>
      <Form.Item
        label={translate('formInputs.name')}
        name={'name'}
        rules={[commonInputRules.name, commonInputRules.required]}>
        <Input count={{ show: true, max: 50 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.city')}
        name={['address', 'city']}
        rules={[commonInputRules.nameUkr]}>
        <Input count={{ show: true, max: 50 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.region')}
        name={['address', 'region']}
        rules={[commonInputRules.nameUkr]}>
        <Input count={{ show: true, max: 50 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.street')}
        name={['address', 'street']}
        rules={[commonInputRules.street]}>
        <Input
          addonBefore={<SelectStreetType options={streetTypes} />}
          count={{ show: true, max: 200 }}
          allowClear
        />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.house')}
        name={['address', 'house']}
        rules={[commonInputRules.numberOfBuild]}>
        <Input count={{ show: true, max: 10 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.phone')}
        name={'phone'}
        rules={[
          commonInputRules.required,
          phoneNumberRules.phone,
          phoneNumberRules.phoneMin,
          phoneNumberRules.phoneMax
        ]}>
        <Input count={{ show: true, max: 12 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.email')}
        name={'email'}
        rules={[commonInputRules.email]}>
        <Input count={{ show: true, max: 50 }} allowClear />
      </Form.Item>
      <Form.Item label={translate('formInputs.site')} name={'site'}>
        <Input count={{ show: true, max: 50 }} allowClear />
      </Form.Item>
      <Form.Item
        label={translate('formInputs.expirationDays')}
        name={'expirationDays'}
        rules={[commonInputRules.expirationDays]}>
        <Input type={'number'} min={1} max={30} />
      </Form.Item>
    </Card>
  );
};
