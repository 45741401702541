import { GetListParams } from '@refinedev/core';
import { EApiRoutes } from '../../types/api-routes';
import { http } from '../axios';

export const getPlaceDescriptionList = async (params: GetListParams) => {
  const requestParams: any = {};

  if (params.pagination) {
    requestParams.limit = params.pagination.pageSize;
  }

  if (params.filters && params.filters.length > 0) {
    requestParams.search = params.filters[0].value;
  }

  try {
    const { data } = await http.get(EApiRoutes.PlaceParcelDescr, { params: requestParams });
    return data;
  } catch (error) {
    console.log(error);
  }
  return [];
};
