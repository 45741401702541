import { WarningOutlined } from '@ant-design/icons';
import { CreateButton, DeleteButton, EditButton, useTable } from '@refinedev/antd';
import { useTranslate } from '@refinedev/core';
import { Input, List, Space, Table, Tooltip, Typography } from 'antd';
import debounce from 'lodash/debounce';
import { FC, memo, useMemo } from 'react';
import { SearchNoData } from '../../components/search-no-data';

const DeleteConfirmMessage = memo(() => {
  const translate = useTranslate();
  return (
    <>
      <p>{translate('popConfirm.contragents.deleteConfirmMessage.row1')}</p>
      <p>{translate('popConfirm.contragents.deleteConfirmMessage.row2')}</p>
      <p>{translate('popConfirm.contragents.deleteConfirmMessage.row3')}</p>
    </>
  );
});

export const ContragentsList: FC = () => {
  const translate = useTranslate();
  const { tableProps, setFilters } = useTable({
    resource: 'contragents',
    syncWithLocation: true
  });

  const onSearchHandler = useMemo(() => {
    return debounce((event) => {
      if ('value' in event.target) {
        if (event.target.value === '') {
          setFilters([], 'replace');
        } else {
          setFilters([
            {
              field: 'name',
              operator: 'contains',
              value: event.target.value
            }
          ]);
        }
      }
    }, 500);
  }, [setFilters]);

  return (
    <List>
      <Space
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingBottom: 16
        }}>
        <CreateButton>{translate('buttons.create')}</CreateButton>
      </Space>
      <Table {...tableProps} rowKey={'id'} locale={{ emptyText: <SearchNoData /> }}>
        <Table.Column
          dataIndex="name"
          title={
            <Space>
              <Typography.Text strong>
                {translate('pages.contragents.list.columns.name')}
              </Typography.Text>
              <Input
                size={'middle'}
                placeholder={translate('pages.contragents.list.columns.searchPlaceholder')}
                onInput={onSearchHandler}
              />
            </Space>
          }
        />
        <Table.Column dataIndex="phone" title={translate('pages.contragents.list.columns.phone')} />
        <Table.Column
          dataIndex="expirationDays"
          title={translate('pages.contragents.list.columns.expirationDays')}
        />
        <Table.Column
          dataIndex="actions"
          title={translate('pages.contragents.list.columns.actions')}
          render={(_, record: any) => {
            return (
              <Space>
                <EditButton
                  title={translate('buttons.edit')}
                  hideText
                  size="small"
                  recordItemId={record.id}
                />
                <DeleteButton
                  title={translate('buttons.delete')}
                  hideText
                  size={'small'}
                  confirmTitle={(<DeleteConfirmMessage />) as unknown as string}
                  confirmOkText={translate('buttons.delete')}
                  confirmCancelText={translate('buttons.cancel')}
                  successNotification={{
                    description: translate('notifications.success'),
                    message: translate('notifications.contragents.deleteSuccess'),
                    type: 'success'
                  }}
                  errorNotification={{
                    description: translate('notifications.error'),
                    message: translate('notifications.contragents.deleteError'),
                    type: 'error'
                  }}
                  recordItemId={record.id}
                />
                {record.expirationDays && record.filials.length > 0 ? null : (
                  <Tooltip title={translate('pages.contragents.needToConfigure')}>
                    <WarningOutlined style={{ color: 'orange' }} />
                  </Tooltip>
                )}
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
