import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';

type FilialListItem = {
  id: string;
  name: string;
  toRemove: boolean;
};

type ContragentContextProps = {
  filialsList: Array<FilialListItem>;
  initFilials: (filialsIds: Array<string>) => void;
  addFilial: (filial: FilialListItem) => boolean;
  removeFilial: (id: string) => boolean;
  isContragentFilialsFormTouched: boolean;
  setIsContragentFilialsFormTouched: Dispatch<SetStateAction<boolean>>;
};

export const ContragentContext = createContext<ContragentContextProps>({
  filialsList: [],
  initFilials: () => null,
  addFilial: () => false,
  removeFilial: () => false,
  isContragentFilialsFormTouched: false,
  setIsContragentFilialsFormTouched: () => null
});

export const ContragentContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [filialsList, setFilialsList] = useState<Array<FilialListItem>>([]);
  const [isContragentFilialsFormTouched, setIsContragentFilialsFormTouched] =
    useState<boolean>(false);

  const initFilials: ContragentContextProps['initFilials'] = useCallback(
    (filialsIds: Array<string>) => {
      const filials = filialsIds.map((id) => ({ id, name: '', toRemove: true }));
      setFilialsList(filials);
    },
    []
  );

  const addFilial: ContragentContextProps['addFilial'] = useCallback(
    (filial: FilialListItem) => {
      const newState = [...filialsList];

      const isInArray = newState.some((item) => item.id === filial.id);
      if (!isInArray) {
        newState.push(filial);
        setFilialsList(newState);
      }

      return !isInArray;
    },
    [filialsList]
  );

  const removeFilial: ContragentContextProps['removeFilial'] = useCallback(
    (id: string) => {
      const newState = [...filialsList].filter((item) => item.id !== id);

      const oldListLength = filialsList.length;
      const newListLength = newState.length;

      setFilialsList(newState);

      return oldListLength !== newListLength;
    },
    [filialsList]
  );

  const value = useMemo(() => {
    return {
      filialsList,
      initFilials,
      addFilial,
      removeFilial,
      isContragentFilialsFormTouched,
      setIsContragentFilialsFormTouched
    };
  }, [addFilial, filialsList, initFilials, isContragentFilialsFormTouched, removeFilial]);

  return <ContragentContext.Provider value={value}>{children}</ContragentContext.Provider>;
};
