import { useTranslate } from '@refinedev/core';
import { Tabs } from 'antd';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const ParcelTabs: FC = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const activeKey = location.pathname.includes('/parcel/config-statuses')
    ? 'config-statuses'
    : 'info';

  const onTabChange = (key: string) => {
    navigate(`/parcel/${key}`);
  };

  return (
    <Tabs
      activeKey={activeKey}
      onChange={onTabChange}
      items={[
        {
          key: 'info',
          label: translate('pages.parcelInfo.list.title')
        },
        {
          key: 'config-statuses',
          label: translate('pages.parcelConfigStatuses.list.title')
        }
      ]}
    />
  );
};
