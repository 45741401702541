//TODO: кінцевим етапом оптимізувати компонент, (дубльований код з parcel-info перевикористовувати)
import { ClearOutlined, ExportOutlined } from '@ant-design/icons';
import { List, useModal, useTable } from '@refinedev/antd';
import { CrudFilters, HttpError, useTranslate } from '@refinedev/core';
import { Button, DatePicker, Drawer, Modal, Select, Space, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ParcelConfigStatusesFilterForm } from '../../components/forms';
import { ParcelTabs } from '../../components/parcel-tabs';
import { useFilterDrawer } from '../../hooks';
import { IParcelConfigFilterVariables } from '../../types/parcel-config-statuses';

const { Paragraph } = Typography;
const { Column, ColumnGroup } = Table;

//TODO: remove mook data, after use api
const statusOptions = [
  { label: 'Не прийнята', value: 'not_accepted' },
  { label: 'Отримана', value: 'received' },
  { label: 'Створена', value: 'created' },
  { label: 'Повернена', value: 'returned' }
];

//TODO: remove mook data, after use api
const mockData = Array.from({ length: 20 }, (_, index) => ({
  key: index,
  contragent: 'МАУДБУ',
  orderId: `098765-${index}`,
  parcelId: `123456-${index}`,
  phone: `380994567${100 + index}`,
  status: 'created',
  dateStatusChange: '01.09.2024',
  statuses: {
    inTransit: index % 2 === 0 ? '01.09.2024' : null,
    accepted: index % 3 === 0 ? '02.09.2024' : null,
    notAccepted: index % 4 === 0 ? '03.09.2024' : null,
    overdue: null,
    received: index % 5 === 0 ? '04.09.2024' : null,
    notReceived: null,
    returned: index % 6 === 0 ? '05.09.2024' : null
  }
}));

export const ParcelConfigStatusesList: FC = () => {
  const navigate = useNavigate();
  const translate = useTranslate();

  const { isOpen, openDrawer, closeDrawer } = useFilterDrawer();
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  //TODO: деякі стейти будуть видалені коли буде використовуватись апі
  const [massStatus, setMassStatus] = useState<string | null>(null);
  const [massDate, setMassDate] = useState<string | null>(null);
  const [dataSource, setDataSource] = useState(mockData);
  const [editData, setEditData] = useState<any>({});

  const { show, close, modalProps } = useModal();

  const { tableProps, searchFormProps, filters } = useTable<
    //TODO: add type
    any,
    HttpError,
    IParcelConfigFilterVariables
  >({
    resource: 'parcel-config-statuses',
    syncWithLocation: true,
    onSearch: (params) => {
      const filters: CrudFilters = createFilters(params);

      if (filters.length === 0) {
        setFiltersApplied(false);
        return [];
      }

      setFiltersApplied(true);
      return filters;
    },
    queryOptions: {
      enabled: filtersApplied
    }
  });

  const createFilters = useCallback(
    (params: IParcelConfigFilterVariables): CrudFilters => {
      const { dataForSearch, searchBy } = params;
      const filters: CrudFilters = [];

      if (dataForSearch && searchBy) {
        const searchValues = dataForSearch.split('\n').filter((value) => value.trim() !== '');
        filters.push({ field: searchBy, operator: 'in', value: searchValues });
      }

      closeDrawer();
      return filters;
    },
    [closeDrawer]
  );

  const clearFilters = useCallback(() => {
    searchFormProps.form?.resetFields();
    setFiltersApplied(false);
    navigate('/parcel/config-statuses');
  }, [navigate, searchFormProps.form]);

  const getParcelCount = useCallback(() => {
    if (tableProps.pagination && 'total' in tableProps.pagination && filtersApplied) {
      return tableProps.pagination.total || 0;
    }
    return 0;
  }, [tableProps.pagination, filtersApplied]);

  const handleExport = useCallback(async () => {
    //TODO: added functional for export to excel
    console.log('handleExport to Excel');
  }, []);

  const handleMassUpdate = useCallback(() => {
    // TODO: added functional for massUpdate
    setDataSource((prevData) =>
      prevData.map((item) =>
        selectedRowKeys.includes(item.key)
          ? {
              ...item,
              status: massStatus || item.status,
              dateStatusChange: massDate || item.dateStatusChange
            }
          : item
      )
    );
    close();
  }, [selectedRowKeys, massStatus, massDate, close]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys: React.Key[]) => {
      setSelectedRowKeys(keys);
    }
  };

  const saveChanges = useCallback(() => {
    // TODO: added functional for pointUpdate
    const updatedData = dataSource.map((item) =>
      selectedRowKeys.includes(item.key)
        ? {
            ...item,
            status: editData[item.key]?.status || item.status,
            dateStatusChange: editData[item.key]?.date || item.dateStatusChange
          }
        : item
    );
    setDataSource(updatedData);
    setSelectedRowKeys([]);
    setEditData({});
  }, [dataSource, selectedRowKeys, editData]);

  useEffect(() => {
    const hasFilters = filters.length > 0;

    if (hasFilters) {
      clearFilters();
    }
  }, []);

  return (
    <>
      <ParcelTabs />
      <List
        breadcrumb={false}
        headerButtons={({ defaultButtons }) => [
          <Fragment key="default-buttons">{defaultButtons}</Fragment>,
          <Button key="apply-filter" type="primary" onClick={openDrawer}>
            {translate('buttons.applyFilter')}
          </Button>,
          filtersApplied && (
            <Button
              key="clear-filter"
              type="default"
              onClick={clearFilters}
              icon={<ClearOutlined />}>
              {translate('buttons.clearFilter')}
            </Button>
          )
        ]}>
        <Space
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 16
          }}>
          <Paragraph style={{ marginBottom: 0 }}>
            {translate('notifications.parcelInfo.parcelCount')}: {getParcelCount()}
          </Paragraph>
          <Tooltip title={translate('buttons.exportExcel')}>
            <Button
              type="default"
              shape="circle"
              icon={<ExportOutlined />}
              onClick={handleExport}
            />
          </Tooltip>
        </Space>
        <Table
          {...tableProps}
          //TODO: change after, use data from tableProps.dataSource
          dataSource={dataSource}
          // dataSource={filtersApplied ? tableProps.dataSource : []}
          rowSelection={rowSelection}
          rowKey="key"
          size="middle"
          pagination={false}
          bordered
          loading={filtersApplied && tableProps.loading}
          locale={{
            emptyText: !filtersApplied
              ? translate('notifications.parcelInfo.tryFilter')
              : translate('notifications.parcelInfo.noData')
          }}>
          <Column title="№" render={(_, __, index) => index + 1} />
          <Column
            title={translate('pages.parcelConfigStatuses.list.columns.contragent')}
            dataIndex="contragent"
          />
          <Column
            title={translate('pages.parcelConfigStatuses.list.columns.orderId')}
            dataIndex="orderId"
          />
          <Column
            title={translate('pages.parcelConfigStatuses.list.columns.parcelId')}
            dataIndex="parcelId"
          />
          <Column
            title={translate('pages.parcelConfigStatuses.list.columns.guestPhone')}
            dataIndex="phone"
          />

          <ColumnGroup title={translate('pages.parcelConfigStatuses.list.columns.historyStatus')}>
            <Column
              title={translate('pages.parcelConfigStatuses.list.columns.inTransit')}
              render={(record) => record.statuses.inTransit || '-'}
              align="center"
            />
            <Column
              title={translate('pages.parcelConfigStatuses.list.columns.accepted')}
              render={(record) => record.statuses.accepted || '-'}
              align="center"
            />
            <Column
              title={translate('pages.parcelConfigStatuses.list.columns.notAccepted')}
              render={(record) => record.statuses.notAccepted || '-'}
              align="center"
            />
            <Column
              title={translate('pages.parcelConfigStatuses.list.columns.overdue')}
              render={(record) => record.statuses.overdue || '-'}
              align="center"
            />
            <Column
              title={translate('pages.parcelConfigStatuses.list.columns.received')}
              render={(record) => record.statuses.received || '-'}
              align="center"
            />
            <Column
              title={translate('pages.parcelConfigStatuses.list.columns.notReceived')}
              render={(record) => record.statuses.notReceived || '-'}
              align="center"
            />
            <Column
              title={translate('pages.parcelConfigStatuses.list.columns.returned')}
              render={(record) => record.statuses.returned || '-'}
              align="center"
            />
          </ColumnGroup>

          <ColumnGroup title={translate('pages.parcelConfigStatuses.list.columns.editStatus')}>
            <Column
              title={translate('pages.parcelConfigStatuses.list.columns.currentStatus')}
              render={(record) =>
                selectedRowKeys.includes(record.key) ? (
                  <Select
                    defaultValue={record.status}
                    style={{ width: '100%' }}
                    options={statusOptions}
                    onChange={(value) => {
                      setEditData((prev: any) => ({
                        ...prev,
                        [record.key]: { ...prev[record.key], status: value }
                      }));
                    }}
                  />
                ) : (
                  statusOptions.find((opt) => opt.value === record.status)?.label
                )
              }
            />
            <Column
              title={translate('pages.parcelConfigStatuses.list.columns.dateChangeStatus')}
              render={(record) =>
                selectedRowKeys.includes(record.key) ? (
                  <DatePicker
                    defaultValue={dayjs(record.dateStatusChange, 'DD.MM.YYYY')}
                    format="DD.MM.YYYY"
                    onChange={(date, dateString) => {
                      setEditData((prev: any) => ({
                        ...prev,
                        [record.key]: { ...prev[record.key], date: dateString }
                      }));
                    }}
                  />
                ) : (
                  record.dateStatusChange
                )
              }
            />
          </ColumnGroup>
        </Table>
        <Space
          style={{
            display: 'flex',
            marginTop: 16
          }}>
          <Paragraph style={{ marginBottom: 0 }}>
            {translate('pages.parcelConfigStatuses.checkParcel')}:{selectedRowKeys.length}
          </Paragraph>
        </Space>
        <Space style={{ marginTop: 16 }}>
          <Button type="primary" disabled={!selectedRowKeys.length} onClick={saveChanges}>
            {translate('buttons.changeStatus')}
          </Button>
          <Button type="primary" onClick={show}>
            {translate('buttons.globalChangeStatus')}
          </Button>
          <Button
            type="default"
            onClick={() => setSelectedRowKeys([])}
            disabled={!selectedRowKeys.length}>
            {translate('buttons.clearStatus')}
          </Button>
        </Space>

        <Modal
          {...modalProps}
          title={translate('pages.parcelConfigStatuses.globalChangeStatus')}
          onOk={handleMassUpdate}
          onCancel={close}
          okButtonProps={{ disabled: !massStatus || !massDate }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Select
              placeholder={translate('pages.parcelConfigStatuses.setStatus')}
              options={statusOptions}
              onChange={(value) => setMassStatus(value)}
              style={{ width: '100%' }}
            />
            <DatePicker
              format="DD.MM.YYYY"
              onChange={(date, dateString) => {
                setMassDate(`${dateString}`);
              }}
              style={{ width: '100%' }}
            />
          </Space>
        </Modal>
      </List>
      <Drawer
        title={translate('pages.parcelInfo.list.titleFilter')}
        onClose={closeDrawer}
        open={isOpen}>
        <ParcelConfigStatusesFilterForm searchFormProps={searchFormProps} />
      </Drawer>
    </>
  );
};
