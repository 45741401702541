export const phoneNumberRules = {
  phone: {
    pattern: /^380\d*$/,
    message: 'Номер телефону починається з 380. Символи заборонені'
  },
  phoneMin: {
    message: 'Недостатня довжина номеру телефону. Формат - 380XX XXX XX XX',
    min: 12
  },
  phoneMax: {
    message: 'Забагато цифр. Формат - 380XX XXX XX XX',
    max: 12
  }
};
