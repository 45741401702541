import { useTranslate } from '@refinedev/core';
import { Space, Typography } from 'antd';
import { memo } from 'react';

export const SearchNoData = memo(() => {
  const translate = useTranslate();
  return (
    <Space direction="vertical" align="center" style={{ width: '100%', padding: '50px 0' }}>
      <Typography.Title level={4}>{translate('pages.common.searchNoData')}</Typography.Title>
    </Space>
  );
});
