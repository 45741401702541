import { GetListParams } from '@refinedev/core';
import { ContragentsResponseType } from '@shared-types';
import { EApiRoutes } from '../../types/api-routes';
import { http } from '../axios';

export const getContragents = async (params: GetListParams): Promise<ContragentsResponseType> => {
  let requestParams: any = {};

  if (params.pagination) {
    //TODO: use after (server added pagination mode "off") for get all item
    // requestParams.paginationMode = params.pagination.mode;
    requestParams.page = params.pagination.current;
    requestParams.pageSize = params.pagination.pageSize;
  }

  if (params.filters && params.filters.length > 0) {
    requestParams = {
      // eslint-disable-next-line
      // @ts-ignore
      filterBy: params.filters[0].field,
      filter: params.filters[0].value
    };
  }

  return await http.get(EApiRoutes.Contragents, { params: requestParams }).then((res) => {
    return res.data;
  });
};
