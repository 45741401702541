import { ClearOutlined, ExportOutlined } from '@ant-design/icons';
import { DateField, FilterDropdown, List, useTable } from '@refinedev/antd';
import { CrudFilters, HttpError, useSelect, useTranslate } from '@refinedev/core';
import { Button, DatePicker, Drawer, Input, Space, Table, Tooltip, Typography } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ParcelInfoFilterForm } from '../../components/forms';
import { ParcelTabs } from '../../components/parcel-tabs';
import { useFilterDrawer } from '../../hooks';
import { mookDataParcelInfo } from '../../mookData';
import { IParcelInfoFilterVariables } from '../../types/parcel-info';
import { exportToExcel } from './parcel-info.utils';

const { Paragraph } = Typography;

export const ParcelInfoList: FC = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { isOpen, openDrawer, closeDrawer } = useFilterDrawer();

  const [filtersApplied, setFiltersApplied] = useState(false);

  //TODO: add type
  const { tableProps, searchFormProps, filters } = useTable<
    any,
    HttpError,
    IParcelInfoFilterVariables
  >({
    resource: 'parcel-info',
    syncWithLocation: true,
    onSearch: (params) => {
      const filters: CrudFilters = createFilters(params);

      if (filters.length === 0) {
        setFiltersApplied(false);
        return [];
      }

      setFiltersApplied(true);
      return filters;
    },
    queryOptions: {
      enabled: filtersApplied
    }
  });

  const { options: contragentsOptions } = useSelect({
    resource: 'contragents',
    //TODO: remove after (server added pagination mode "off")
    pagination: { pageSize: 9999 },
    optionLabel: (item) => `${item.name}`,
    optionValue: (item) => `${item.id}`
  });

  const { options: parcelStatusesOptions } = useSelect({
    resource: 'fix/parcel-statuses'
  });

  const clearFilters = useCallback(() => {
    searchFormProps.form?.resetFields();
    setFiltersApplied(false);
    navigate('/parcel/info');
  }, [navigate, searchFormProps.form]);

  const createFilters = useCallback(
    (params: IParcelInfoFilterVariables): CrudFilters => {
      const { createdAt, contragent, status } = params;
      const filters: CrudFilters = [];

      if (status?.length) {
        filters.push({ field: 'status', operator: 'in', value: status });
      }

      if (contragent?.length) {
        filters.push({ field: 'contragent', operator: 'in', value: contragent });
      }

      const [start, end] = createdAt
        ? [createdAt[0]?.format('YYYY-MM-DD'), createdAt[1]?.format('YYYY-MM-DD')]
        : [null, null];

      if (start && end) {
        filters.push(
          { field: 'start', operator: 'gte', value: start },
          { field: 'end', operator: 'lte', value: end }
        );
      }
      closeDrawer();
      return filters;
    },
    [closeDrawer]
  );

  const getParcelCount = useCallback(() => {
    if (tableProps.pagination && 'total' in tableProps.pagination && filtersApplied) {
      return tableProps.pagination.total || 0;
    }
    return 0;
  }, [tableProps.pagination, filtersApplied]);

  const handleExport = useCallback(async () => {
    //TODO: remove mook data, after use api

    // const response = await axios.get('/api/parcel-info', {
    //   params: {
    //     filters: JSON.stringify(filters)
    //   }
    // });

    const data = mookDataParcelInfo.data;
    await exportToExcel(data, 'Parcel_Info');
  }, []);

  useEffect(() => {
    const hasActiveFilters = filters.some((filter) => filter.value !== null);
    setFiltersApplied(hasActiveFilters);
  }, [filters]);

  useEffect(() => {
    const hasFilters = filters.length > 0;

    if (hasFilters) {
      clearFilters();
    }
  }, []);

  return (
    <>
      <ParcelTabs />
      <List
        breadcrumb={false}
        headerButtons={({ defaultButtons }) => [
          <Fragment key="default-buttons">{defaultButtons}</Fragment>,
          <Button key="apply-filter" type="primary" onClick={openDrawer}>
            {translate('buttons.applyFilter')}
          </Button>,
          filtersApplied && (
            <Button
              key="clear-filter"
              type="default"
              onClick={clearFilters}
              icon={<ClearOutlined />}>
              {translate('buttons.clearFilter')}
            </Button>
          )
        ]}>
        <Space
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 16
          }}>
          <Paragraph style={{ marginBottom: 0 }}>
            {translate('notifications.parcelInfo.parcelCount')}: {getParcelCount()}
          </Paragraph>
          <Tooltip title={translate('buttons.exportExcel')}>
            <Button
              type="default"
              shape="circle"
              icon={<ExportOutlined />}
              onClick={handleExport}
            />
          </Tooltip>
        </Space>
        <Table
          {...tableProps}
          rowKey="id"
          dataSource={filtersApplied ? tableProps.dataSource : []}
          loading={filtersApplied && tableProps.loading}
          locale={{
            emptyText: !filtersApplied
              ? translate('notifications.parcelInfo.tryFilter')
              : translate('notifications.parcelInfo.noData')
          }}>
          <Table.Column title="№" render={(_, __, index) => index + 1} />
          <Table.Column
            dataIndex="contragent"
            title={translate('pages.parcelInfo.list.columns.contragent')}
            render={(value: any) => value.name}
          />
          <Table.Column
            dataIndex="filial"
            title={translate('pages.parcelInfo.list.columns.city')}
            render={(value: any) => value.addressCity}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex="filial"
            title={translate('pages.parcelInfo.list.columns.filialId')}
            render={(value: any) => value.id}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex="filial"
            title={translate('pages.parcelInfo.list.columns.filialName')}
            render={(value: any) => value.name}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex="orderId"
            title={translate('pages.parcelInfo.list.columns.orderId')}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex="parcelId"
            title={translate('pages.parcelInfo.list.columns.parcelId')}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex="status"
            title={translate('pages.parcelInfo.list.columns.status')}
          />
          <Table.Column
            dataIndex="createdAt"
            sorter
            title={translate('pages.parcelInfo.list.columns.createdAt')}
            render={(value: Date) => <DateField value={value} format="DD.MM.YYYY" />}
          />
          <Table.Column
            dataIndex="dateChangeStatus"
            title={translate('pages.parcelInfo.list.columns.dateChangeStatus')}
            render={(value: Date) => <DateField value={value} format="DD.MM.YYYY" />}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <span>
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={(date) => {
                      const formattedDate = date ? date.format('YYYY-MM-DD') : undefined;
                      props.confirm({ closeDropdown: true });
                      props.setSelectedKeys(formattedDate ? [formattedDate] : []);
                    }}
                  />
                </span>
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex="guestPhone"
            title={translate('pages.parcelInfo.list.columns.guestPhone')}
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )}
          />
        </Table>
      </List>
      <Drawer
        title={translate('pages.parcelInfo.list.titleFilter')}
        onClose={closeDrawer}
        open={isOpen}>
        <ParcelInfoFilterForm
          contragentsOptions={contragentsOptions}
          parcelStatusesOptions={parcelStatusesOptions}
          searchFormProps={searchFormProps}
        />
      </Drawer>
    </>
  );
};
